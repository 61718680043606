import React from 'react';
import PropTypes from 'prop-types';
import CtaRealPlace from '../../global/CtaRealPlace';
import tracking from '../../services/tracking_service';

class PlaceToQuiz extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      trackingLabel: props.trackingLabel ? props.trackingLabel : `${props.areaPage}PgToBeginQuizFlow`
    }
  }

  componentDidMount() {
    this.tracking = tracking;
  }

  nextStep(key, val) {
    let slug = "";

    if (val && val.geocode && val.geocode.slug) {
      slug = val.geocode.slug
    } else {
      val.geocode?.city?.toLowerCase().replace(' ', '-') + '-' + val.geocode?.state?.toLowerCase();
    }

    this.scrollIntoQuiz(slug, val.geocode);
  }

  scrollIntoQuiz(slug, geocode) {
    if (this.props.areaPage) {
      this.tracking.trackEvent('Quiz', `${this.props.trackingAction}`, `${this.state.trackingLabel}`, 1);
    }

    if (slug === 'United States') {
      return location = '/find-agent/quiz';
    }

    if (!this.props.allowEmptyGeo) {
      slug = slug || 'san-francisco-ca';
    }

    if (!!slug) {
      slug = slug.replace(/-/g, ' ');
      slug = slug.trim();
      slug = slug.replace(/ /g, '-');
    }

    location = this.props.urlSchema ? this.buildUrlFromSchema(slug) : this.buildQuizUrl(slug, geocode.full_address);
  }

  buildUrlFromSchema (slug) {
    const { url, urlSchema } = this.props;
    return urlSchema.replace('url', url).replace('area', slug);
  }

  buildQuizUrl (slug, address) {
    const {url, areaPage, omitBadAddress} = this.props;
    let baseUrl = `${url}?area=${slug}`

    if (address) {
      if (omitBadAddress) {
        // Check that the address includes numbers and is not a city
        const regex = /\d+/

        if (regex.test(address)) {
          baseUrl = `${url}?area=${slug}&address=${address}`
        }
      } else {
        baseUrl = `${url}?area=${slug}&address=${address}`
      }
    }

    return areaPage ? `${baseUrl}&area_page=${areaPage}` : baseUrl;
  }

  render() {
    const {
      trackPageName,
      trackPagePosition,
      trackEventCategory,
      id,
      buttonText,
      placeHolder,
      inputColor,
      types,
      showLoading,
      showSearchIcon,
      allowEmptyGeo,
      omitBadAddress,
   } = this.props;

    return (
      <div className='place-to-quiz input-group'>
        <CtaRealPlace
          callback={this.nextStep.bind(this)}
          trackPageName={trackPageName}
          trackPagePosition={trackPagePosition}
          id={id}
          place={this.props.default}
          buttonText={buttonText}
          placeHolder={placeHolder}
          inputColor={inputColor}
          showLoading={showLoading}
          types={types}
          showSearchIcon={showSearchIcon}
          onButtonClick={() => {
            tracking.trackEvent(trackEventCategory, 'Tap', this.state.trackingLabel);
          }}
          ignoreBlankInputError={allowEmptyGeo}
          omitBadAddress={omitBadAddress}
        />
      </div>
    );
  }
};

PlaceToQuiz.propTypes = {
  id: PropTypes.string.isRequired,
  trackEventCategory: PropTypes.string.isRequired,
  trackingLabel: PropTypes.string.isRequired,
  showSearchIcon: PropTypes.bool,
  allowEmptyGeo: PropTypes.bool,
}

PlaceToQuiz.defaultProps = {
  trackingAction: 'Submit',
  showSearchIcon: false,
  allowEmptyGeo: false,
}

export default PlaceToQuiz
