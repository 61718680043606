import React from 'react';
import GoogleIcon from '../icons/GoogleIcon';
import StarIcon from '../../../components/icons/StarIcon';
import '../../../styles/agentMatchingSem/ReviewAverage.scss';

const googleHlLink =
  'https://www.google.com/maps/place/HomeLight/@33.4634917,-111.9234632,17z/data=!3m1!4b1!4m5!3m4!1s0x872b0926741b70a3:0x251ab6940403a8d6!8m2!3d33.4634917!4d-111.9234632';

const ReviewAverage = () => (
  <div className="review-average">
    <a href={googleHlLink} target="_blank" className="review-average__google-link">
      <div className="review-average__score-wrapper">
        <div className="review-average__stars">
          <StarIcon style={{}} />
          <StarIcon style={{}} />
          <StarIcon style={{}} />
          <StarIcon style={{}} />
          <StarIcon style={{}} fill='' fillPercent={80} />
        </div>
        <div className='review-average__score'>4.8</div>
      </div>
    </a>
    <div className="review-average__source-reference">
      <GoogleIcon />
      <div className="review-average__source-text">Average of 290+ customer reviews</div>
    </div>
  </div>
);

export default ReviewAverage;
