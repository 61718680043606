import React from 'react';
import PropTypes from 'prop-types';
import RealtorKW from '../../components/global/RealtorKW';
import '../../styles/agentMatchingSem/ConnectWithAgents.scss'
import topAgentCardPng from '../../../assets/images/v4/agent_matching_sem/top-agent-cards.png'
import topAgentCardWebp from '../../../assets/images/v4/agent_matching_sem/top-agent-cards.webp'

const ConnectWithAgents = ({ useRealtor }) => (
  <div className='connect-with-agents page-section'>
    <div className="content">
      <div className="text-wrapper">
        <h2 className="title">
          We connect you with multiple top <RealtorKW realtor={useRealtor}>agents</RealtorKW>,
          you choose the best one
        </h2>

        <p className="description">
          Compare profiles and talk to <RealtorKW realtor={useRealtor}>agents</RealtorKW> before choosing
          the best <RealtorKW realtor={useRealtor}>agent</RealtorKW> for you.
        </p>
      </div>

      <div className="img-wrapper">
        <picture>
          <source type="image/webp" srcSet={topAgentCardWebp} />
          <source type="image/png" srcSet={topAgentCardPng} />
          <img src={topAgentCardPng} alt="" />
        </picture>
      </div>
    </div>
  </div>
)

ConnectWithAgents.propTypes = {
  useRealtor: PropTypes.bool,
}

ConnectWithAgents.defaultProps = {
  useRealtor: false
}

export default ConnectWithAgents;
