import React from 'react';
import PropTypes from 'prop-types';
import HeaderLogo from '../../../components/global/HeaderLogo';
import AgentMatchingHero from '../AgentMatchingHero';
import ServiceProps from '../ServiceProps';
import Testimonials from '../Testimonials';
import '../../../styles/agentMatchingSem/base.scss';
import '../../../styles/agentMatchingSem/TopBar.scss';
import Brands from "../Brands";
import WhyTopAgents from "../WhyTopAgents";
import CtaBanner from '../CtaBanner';
import Disclaimer from '../Disclaimer';
import ConnectWithAgents from '../ConnectWithAgents';

const AgentMatching = ({
  variant,
  useRealtor,
  cityName,
  cityNameWithState,
  cityAgentsMetrics,
  buyerQuizPath,
  sellerQuizPath,
  comboQuizPath,
  pickQuizPath,
  hlLogoPath,
  type,
}) => (
  <div className={`agent-matching agent-matching--realtor-${useRealtor}`}>
    <div className='agent-matching__top-bar'>
      <div className='agent-matching__top-bar-container'>
        <HeaderLogo logoUrl={hlLogoPath} />
        <a href='tel:8889981909' className='agent-matching__top-phone-number'>
          (888) 998-1909
        </a>
      </div>
    </div>
    <AgentMatchingHero
      variant={variant}
      cityNameWithState={cityNameWithState}
      buyerQuizPath={buyerQuizPath}
      sellerQuizPath={sellerQuizPath}
      comboQuizPath={comboQuizPath}
      useRealtor={useRealtor}
      type={type}
    />
    <ServiceProps useRealtor={useRealtor} />
    <WhyTopAgents
      cityName={cityName}
      cityAgentsMetrics={cityAgentsMetrics}
      useRealtor={useRealtor}
    />
    <Testimonials />
    <ConnectWithAgents useRealtor={useRealtor} />
    <CtaBanner
      pickQuizPath={pickQuizPath}
      cityNameWithState={cityNameWithState}
      useRealtor={useRealtor}
    />
    <Brands />
    <Disclaimer />
  </div>
);

AgentMatching.propTypes = {
  variant: PropTypes.string,
  useRealtor: PropTypes.bool,
  cityName: PropTypes.string,
  cityNameWithState: PropTypes.string,
  cityAgentsMetrics: PropTypes.shape({
    seller_agents_price_percent: PropTypes.string.isRequired,
    top_agents_dom_diff_times: PropTypes.string.isRequired,
    top_agents_avg_rating: PropTypes.string.isRequired,
    buyer_agents_saving_percent: PropTypes.string.isRequired,
  }),
  buyerQuizPath: PropTypes.string.isRequired,
  sellerQuizPath: PropTypes.string.isRequired,
  comboQuizPath: PropTypes.string.isRequired,
  pickQuizPath: PropTypes.string.isRequired,
  hlLogoPath: PropTypes.string.isRequired,
  type: PropType.sstring
};

AgentMatching.defaultProps = {
  variant: 'control',
  useRealtor: false,
  cityName: null,
  cityAgentsMetrics: {},
  cityNameWithState: null,
};

export default AgentMatching;
