const { initializeSentry } = require("../utils/initializeSentry")
initializeSentry()

require('lazysizes');

noJQ = true;

require("../utils/modernizr-custom.js");

var context = require.context('monolithic_component_pages/agentMatchingSem/entryPoints', true);

var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(context);

require("../styles/agentMatchingSem/base.scss")
