import React from 'react'
import PropTypes from 'prop-types';

const StarIcon = ({ style, fill, fillPercent }) => (
  <svg style={style} viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    {fillPercent && (
      <defs>
        <linearGradient id="star-fill-gradient">
          <stop offset={`${fillPercent}%`} stopColor="var(--color-filled)"/>
          <stop offset={`${100 - fillPercent}%`} stopColor="var(--color-empty)"/>
        </linearGradient>
      </defs>
    )}
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="the-walkthrough-4-copy" className='star-fill' transform="translate(-652.000000, -3921.000000)" fill={fill}
         fillRule="nonzero">
        <g id="Group-11" transform="translate(652.000000, 3921.000000)">
          <path
            d="M10.7125257,0.79907197 L7.78318506,6.73850068 L1.229178,7.694013 C0.0538529828,7.86447999 -0.417174218,9.31344938 0.435160717,10.1433544 L5.17683454,14.763907 L4.05534121,21.2909982 C3.85347241,22.4708092 5.09608702,23.3545459 6.13683284,22.8027712 L12,19.7209075 L17.8631672,22.8027712 C18.903913,23.35006 20.1465276,22.4708092 19.9446588,21.2909982 L18.8231655,14.763907 L23.5648393,10.1433544 C24.4171742,9.31344938 23.946147,7.86447999 22.770822,7.694013 L16.2168149,6.73850068 L13.2874743,0.79907197 C12.7626155,-0.259617739 11.2418705,-0.273075659 10.7125257,0.79907197 Z"
            id="star-solid"/>
        </g>
      </g>
    </g>
  </svg>
)

StarIcon.propTypes = {
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  }),
  fill: PropTypes.string,
  fillPercent: PropTypes.number
};

StarIcon.defaultProps = {
  style: {
    width: '24px',
    height: '23px'
  },
  fill: '#FFDA6B',
  fillPercent: null
}

export default StarIcon
