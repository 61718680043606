import React from 'react';
import '../../styles/agentMatchingSem/Disclaimer.scss';

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <p className="disclaimer__text">A REALTOR is a member of the National Association of REALTORS® ©2005 - 2022</p>
    </div>
  );
};

export default Disclaimer;
