import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from './LoadingSpinner'
import MapboxPlaceAutocomplete from './MapboxPlaceAutocomplete'
import { geoSearch } from '../services/mapboxGeocoder'

import '../../styles/components/global/CtaRealAddress.scss'

class CtaRealPlace extends React.Component {

  constructor(params) {
    super(params);

    this.state = {
      loading: false,
      address: this.props.place,
      errors: {
        address: false,
        place: false,
        verified: false
      }
    }

    this.setAddress = this.setAddress.bind(this);
    this.continue = this.continue.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.displayErrors = this.displayErrors.bind(this);
    this.shouldDisplayErrors = this.shouldDisplayErrors.bind(this);
  }

  setAddress(geocode) {
    const cleanedAddress = geocode.formatted_address.replace(', USA', '');
    this.setState({
      address: cleanedAddress,
      loading: true,
      geocode
    }, ()=> {
      this.continue()
    });
  }

  continue() {
    let bias = this.props.bias && this.props.bias.length && (this.props.bias.filter(x => x).length === 2) && this.props.bias.join(',');
    let { types, onButtonClick } = this.props;
    let input = document.getElementById(this.props.id);
    let errors = {
      address: false,
      verified: false,
    }

    if (!this.state.geocode) {
      geoSearch(input.value, { types, bias }).then(results => {
        if (results.length <= 0) {
          errors.address = true;
          if (this.props.ignoreBlankInputError) {
            this.props.callback('response', { geocode: '' });
          }
        } else {
          input.value = results[0].description;
          this.props.callback('response', { geocode: results[0] });
          if (onButtonClick) onButtonClick('response', {geocode: results[0]});
          errors.address = false;
        }
      }).catch(err => {
        errors.address = true;
      }).finally(()=> {
        this.setState({loading: false});
      });
    } else {
      this.props.callback('response', { geocode: this.state.geocode });
    }
    this.setState({ loading: false, errors });
  }

  handleButtonClick() {
    const {onButtonClick} = this.props;
    this.continue();
    if (onButtonClick) onButtonClick();
  }

  shouldDisplayErrors() {
    return !this.props.ignoreBlankInputError && this.state.errors?.address
  }

  displayErrors() {
    const { types } = this.props;
    return (
      <div className="error-messages">
        { types === 'place' ? (
          <span className="validity">
            Please enter a valid city and state. <br />Ex: San Francisco, CA
            </span>
        ) : (
            <span className="validity">
              Please enter a complete address. <br />Ex: 123 Main Street Unit 2, City Name, CA 55555
            </span>
          )}
      </div>
    )
  }

  render() {
    const { id, placeHolder, place, inputColor, types, showLoading, buttonText, showSearchIcon } = this.props;
    let { address, loading } = this.state
    let className = 'button';
    let button;

    if (this.state.loading) {
      className += ' loading';
    }

    if (showLoading && loading) {
      button = <LoadingSpinner />;
    } else {
      button = buttonText;
    }

    return (
      <div className={`cta-real-address-component ${this.shouldDisplayErrors() ? 'error' : ''}`}>
        <MapboxPlaceAutocomplete
          id={ id }
          defaultValue={ address || place }
          placeHolder={ placeHolder }
          onSelect={ this.setAddress }
          inputColor={ inputColor }
          types={ types }
          showSearchIcon={showSearchIcon}
        />

        { this.shouldDisplayErrors() && this.displayErrors() }

        <a onClick={ this.handleButtonClick } className={ className }>
          { button }
        </a>
      </div>
    );
  }
};

CtaRealPlace.propTypes = {
  id: PropTypes.string,
  place: PropTypes.string,
  bias: PropTypes.string,
  types: PropTypes.string,
  showLoading: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  trackPagePosition: PropTypes.string,
  trackPageName: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  ignoreBlankInputError: PropTypes.bool,
};

CtaRealPlace.defaultProps = {
  id: "",
  trackPagePosition: "",
  place: "",
  bias: "",
  types: "place",
  showLoading: false,
  placeHolder: "Enter your city and state",
  showSearchIcon: false,
  ignoreBlankInputError: false,
};

export default CtaRealPlace
