
const replaceQueryParam = (queryParamName, newValue) => {
  if (typeof(window) === 'undefined') return "";

  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(queryParamName, newValue);
  return `?${queryParams.toString()}`;
};

const readQueryParam = (queryParam) => {
  if (typeof(window) === 'undefined') return;

  const params = new URLSearchParams(window.location.search);
  return params.get(queryParam);
};

export { replaceQueryParam, readQueryParam };
