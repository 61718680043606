import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlaceToQuiz from '../../components/loadable/global/PlaceToQuiz';
import '../../styles/agentMatchingSem/Hero.scss';
import '../../styles/agentMatchingSem/Cta.scss';
import tracking from '../../components/services/tracking_service';
import { toTitleCase } from '../../utils/toTitleCase';

const HeroCta = ({
  buttonText,
  cityNameWithState,
  buyerQuizPath,
  sellerQuizPath,
  comboQuizPath,
  type,
}) => {
  const [clientType, setClientType] = useState(type || 'sell');

  const CTA_TYPE = {
    'sell': {
      quizPath: sellerQuizPath,
      placeHolder: 'Enter your home address',
      searchType: 'address,place'
    },
    'buy': {
      quizPath: buyerQuizPath,
      placeHolder: 'Enter your city',
      searchType: 'place',
      defaultLocation: cityNameWithState
    },
    'buySell': {
      quizPath: comboQuizPath,
      placeHolder: 'Enter your home address',
      searchType: 'address,place'
    }
  };

  const tabState = tabType => tabType === clientType ? ' hero-cta__tab--active' : '';
  const { defaultLocation, quizPath, placeHolder, searchType } = CTA_TYPE[clientType];

  const handleClick = (type) => {
    setClientType(type)
    tracking.trackEvent("Generic_Agent_Matching", "Tap", `Generic_Agent_Matching_${toTitleCase(type)}_Tab`)
  }

  return (
    <div className='hero-cta'>
      <div className='hero-cta__tabs-wrapper'>
        <div
          className={`hero-cta__tab${tabState('sell')}`}
          onClick={() => handleClick('sell')}
        >
          Sell
        </div>
        <div
          className={`hero-cta__tab${tabState('buy')}`}
          onClick={() => handleClick('buy')}
        >
          Buy
        </div>
        <div
          className={`hero-cta__tab${tabState('buySell')}`}
          onClick={() => handleClick('buySell')}
        >
          Buy & Sell
        </div>
      </div>
      <div className='hero-cta__input'>
        <PlaceToQuiz
          id='agent-matching-hero-cta'
          buttonText={buttonText}
          url={quizPath}
          types={searchType}
          default={defaultLocation}
          placeHolder={placeHolder}
          trackPageName='agentMatchingSem'
          trackPagePosition='hero'
          trackEventCategory='Generic_Agent_Matching'
          trackingAction='Tap'
          trackingLabel='Generic_Agent_Matching_Compare_Agents_Hero_CTA'
          showSearchIcon
          allowEmptyGeo
          omitBadAddress
        />
      </div>
    </div>
  )
};

HeroCta.propTypes = {
  buttonText: PropTypes.string.isRequired,
  cityNameWithState: PropTypes.string,
  buyerQuizPath: PropTypes.string.isRequired,
  sellerQuizPath: PropTypes.string.isRequired,
  comboQuizPath: PropTypes.string.isRequired,
  type: PropTypes.string,
};

HeroCta.defaultProps = {
  cityNameWithState: null,
};

export default HeroCta;
