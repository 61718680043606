import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/agentMatchingSem/Testimonials.scss';

const TestimonialCard = ({ imagePathWebp, imagePathJpg, author, city, testimonial, buySellType }) => {
  return (
    <div className='testimonial-card'>
      <div className='testimonial-card__profile-wrapper'>
        <picture className='testimonial-card__picture'>
          <source type='image/webp' data-srcset={imagePathWebp} />
          <source type='image/jpg' data-srcset={imagePathJpg} />
          <img className='lazyload' data-src={imagePathJpg} alt={`Picture of ${author}`} />
        </picture>
        <div className='testimonial-card__profile-text'>
          <div className='testimonial-card__author'>{author}</div>
          <div className='testimonial-card__city'>{city}</div>
        </div>
      </div>
      <hr className='testimonial-card__divider' />
      <p className='testimonial-card__content'>{testimonial}</p>
      <div className='testimonial-card__client_type'>
        {`${buySellType} with a HomeLight Agent`}
      </div>
    </div>
  )
}

TestimonialCard.propTypes = {
  imagePathWebp: PropTypes.string.isRequired,
  imagePathJpg: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
  buySellType: PropTypes.string.isRequired
}

export default TestimonialCard;
