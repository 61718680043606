import React from 'react';
import ReviewAverage from './global/ReviewAverage';
import BaseCarousel from '../../components/global/BaseCarousel';
import TestimonialCard from './TestimonialCard';
import CoryJpg from '../../images/agentMatchingSem/testimonial-client-cory.jpg';
import CoryWebp from '../../images/agentMatchingSem/testimonial-client-cory.webp';
import PattieJpg from '../../images/agentMatchingSem/testimonial-client-pattie.jpg';
import PattieWebp from '../../images/agentMatchingSem/testimonial-client-pattie.webp';
import ConnieJpg from '../../images/agentMatchingSem/testimonial-client-connie.jpg';
import ConnieWebp from '../../images/agentMatchingSem/testimonial-client-connie.webp';
import LisaJpg from '../../images/agentMatchingSem/testimonial-client-lisa.jpg';
import LisaWebp from '../../images/agentMatchingSem/testimonial-client-lisa.webp';
import ThomasJpg from '../../images/agentMatchingSem/testimonial-client-thomas.jpg';
import ThomasWebp from '../../images/agentMatchingSem/testimonial-client-thomas.webp';
import MonicaJpg from '../../images/agentMatchingSem/testimonial-client-monica.jpg';
import MonicaWebp from '../../images/agentMatchingSem/testimonial-client-monica.webp';
import { useIsMobile } from '../../hooks/useResponsive'
import '../../styles/agentMatchingSem/Testimonials.scss';

const testimonials = [
  {
    author: 'Cory',
    city: 'Ft. Loramie, OH',
    imagePathJpg: CoryJpg,
    imagePathWebp: CoryWebp,
    content: `“Using HomeLight made it to easy to find the top selling real estate agent in our area. The process from beginning to end was so quick and easy. Our realtor got to work right away and was very confident in the listing process. Our home went under contract just 2-3 weeks and sold quickly!”`,
    buySellType: 'Sold'
  },
  {
    author: 'Pattie J.',
    city: 'Cumberland, NJ',
    imagePathJpg: PattieJpg,
    imagePathWebp: PattieWebp,
    content: `“Purely professional from start to finish. I received an immediate response. In less than two months the property was sold for much more than expected. HomeLight is the game changer of real estate.”`,
    buySellType: 'Sold'
  },
  {
    author: 'Connie P.',
    city: 'Newport, RI',
    imagePathJpg: ConnieJpg,
    imagePathWebp: ConnieWebp,
    content: `“I didn't know how to begin the search for a realtor in the area I was interested in purchasing my next home. HomeLight was recommended to me. They were responsive and efficient in getting back to me very quickly, and recommended a realtor who turned out to be absolutely terrific. I would highly recommend them to assist you in your search.”`,
    buySellType: 'Bought'
  },
  {
    author: 'Lisa M.',
    city: 'Valley Village, CA',
    imagePathJpg:LisaJpg,
    imagePathWebp: LisaWebp,
    content: `“When selling my home, I needed a reliable, trustworthy, competent team with excellent communication and coordination skills. HomeLight met those requirements. The sale was handled quickly and flawlessly. I would recommend the HomeLight team to anyone.”`,
    buySellType: 'Sold'
  },
  {
    author: 'Thomas L.',
    city: 'Charlotte, NC',
    imagePathJpg: ThomasJpg,
    imagePathWebp: ThomasWebp,
    content: `“The service that HomeLight provided was a tremendous help. HomeLight was able to match our specific situation and needs to a selection of local realtors and remove a lot of the legwork from the selection process.”`,
    buySellType: 'Bought'
  },
  {
    author: 'Monica M.',
    city: 'Warren, NJ',
    imagePathJpg: MonicaJpg,
    imagePathWebp: MonicaWebp,
    content: `“My husband and I were first time home buyers. After going to HomeLight's website, I answered a few questions and the next day got matched up with our realtor. He was the absolute best, and we were able to find our starter home soon after. HomeLight's service was so easy, and I have them to thank for setting us up with our amazing realtor.”`,
    buySellType: 'Bought'
  }
];

const carouselBreakpoints = [{
  breakpoint: 1024,
  settings: {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true
  }
}, {
  breakpoint: 768,
  settings: {
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: true
  }
}];

const Testimonials = () => {
  const mobile = useIsMobile()

  return (
    <div className='testimonials page-section'>
      <div className='content'>
        <h2>What our clients say</h2>
        <ReviewAverage />
        <div className='testimonials__cards-container'>
          <BaseCarousel
            dots
            arrows={!mobile}
            slidesToShow={3}
            slidesToScroll={3}
            breakpoints={carouselBreakpoints}
            trackingInfo={{
              category: "Generic_Agent_Matching",
              action: "Tap",
              label: "Generic_Agent_Matching_Testimonials_Carousel",
            }}
          >
            {testimonials.map((testimonial) => (
              <div className='testimonials__card-wrapper'>
                <TestimonialCard
                  key={testimonial.author}
                  imagePathWebp={testimonial.imagePathWebp}
                  imagePathJpg={testimonial.imagePathJpg}
                  author={testimonial.author}
                  city={testimonial.city}
                  testimonial={testimonial.content}
                  buySellType={testimonial.buySellType}
                />
              </div>
            ))}
          </BaseCarousel>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
