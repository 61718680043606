import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typewriter from 'typewriter-effect';
import HeroCta from './HeroCta';
import CheckCircleIcon from '../../components/icons/CheckCircleIcon';
import ClockSolidIcon from '../../components/icons/ClockSolidIcon';
import AwardSolidIcon from '../../components/icons/AwardSolidIcon';
import RealtorKW from '../../components/global/RealtorKW';
import '../../styles/agentMatchingSem/Hero.scss';

const AgentMatchingHero = ({
  variant,
  useRealtor,
  cityNameWithState,
  buyerQuizPath,
  sellerQuizPath,
  comboQuizPath,
  type,
}) => {

  const renderStaticValueProps = () => {
    if (variant !== 'variant1') {
      return null;
    }

    return (
      <div className='agent-matching-hero__static-value-props'>
        <div className='agent-matching-hero__static-prop'>
          <CheckCircleIcon />
          100% free
        </div>
        <div className='agent-matching-hero__static-prop'>
          <ClockSolidIcon />
          Less than 2 minutes
        </div>
        <div className='agent-matching-hero__static-prop'>
          <AwardSolidIcon />
          <span>
            Only top <RealtorKW realtor={useRealtor}>agents</RealtorKW>
          </span>
        </div>
      </div>
    )
  }

  const renderTypewriterValueProps = () => {
    if (variant !== 'variant2') {
      return null;
    }

    const valueProps = [
      '100% free',
      'In less than 2 minutes',
      'Get local expertise'
    ];

    return (
      <div className='agent-matching-hero__typewriter-value-props'>
        <Typewriter
          options={{
            strings: valueProps,
            loop: true,
            autoStart: true
          }}
        />
      </div>
    )
  }

  const buttonText = (
    <Fragment>
      Compare <RealtorKW realtor={useRealtor}>Agents</RealtorKW>
    </Fragment>
  )

  return (
    <div className="agent-matching-hero">
      <div className="agent-matching-hero__container">
        <div className="agent-matching-hero__content-wrapper">
          <h1>
            Find the Perfect <RealtorKW realtor={useRealtor}>Real Estate Agent</RealtorKW> for You
          </h1>
          {renderTypewriterValueProps()}
          <div className="agent-matching-hero__subtitle">
            We analyze millions of home sales to find the best performing real estate agents &
            REALTORS<sup className="registered-trademark">®</sup> for your personalized needs.
          </div>
          <HeroCta
            buttonText={buttonText}
            cityNameWithState={cityNameWithState}
            buyerQuizPath={buyerQuizPath}
            sellerQuizPath={sellerQuizPath}
            comboQuizPath={comboQuizPath}
            type={type}
          />
          {renderStaticValueProps()}
        </div>
      </div>
    </div>
  );
};

AgentMatchingHero.propTypes = {
  variant: PropTypes.string.isRequired,
  useRealtor: PropTypes.bool,
  cityNameWithState: PropTypes.string,
  buyerQuizPath: PropTypes.string.isRequired,
  sellerQuizPath: PropTypes.string.isRequired,
  comboQuizPath: PropTypes.string.isRequired,
  type: PropTypes.string,
};

AgentMatchingHero.defaultProps = {
  cityNameWithState: null,
  useRealtor: false
};

export default AgentMatchingHero;
