import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import BillIcon from '../../components/icons/BillIcon'
import CalendarSolidIcon from '../../components/icons/CalendarSolidIcon'
import StarIcon from '../../components/icons/StarIcon'
import RealtorKW from '../../components/global/RealtorKW';
import tracking from '../../components/services/tracking_service';

import { readQueryParam } from '../../utils/queryParamsHelper'

import '../../styles/agentMatchingSem/WhyTopAgents.scss'

import sellerImgPng from '../../../assets/images/v4/agent_matching_sem/why-top-agents-seller.png'
import sellerImgWebp from '../../../assets/images/v4/agent_matching_sem/why-top-agents-seller.webp'
import buyerImgPng from '../../../assets/images/v4/agent_matching_sem/why-top-agents-buyer.png'
import buyerImgWebp from '../../../assets/images/v4/agent_matching_sem/why-top-agents-buyer.webp'

const SELLER_TAB = 'seller'
const BUYER_TAB = 'buyer'
const TAB_QUERY_PARAM = 'tab'
const trackingMap = {
  [SELLER_TAB]: "Selling",
  [BUYER_TAB]: "Buying"
}

const WhyTopAgents = ({ cityName, cityAgentsMetrics, useRealtor }) => {
  const tabParam = readQueryParam(TAB_QUERY_PARAM)
  const [selectedTab, setSelectedTab] = useState(tabParam || SELLER_TAB)

  cityAgentsMetrics ||= {}
  const { seller_agents_price_percent, top_agents_dom_diff_times, top_agents_avg_rating, buyer_agents_saving_percent } = cityAgentsMetrics

  const handleClick = (tab) => {
    setSelectedTab(tab)
    tracking.trackEvent("Generic_Agent_Matching", "Tap", `Generic_Agent_Matching_Why_Work_With_Top_Agents_${trackingMap[tab]}`)
  }

  const sellerAgentPriceCopy = (
    <Fragment>
      Top <RealtorKW realtor={useRealtor}>agents</RealtorKW> help you sell your home for
      { seller_agents_price_percent ? ` ${seller_agents_price_percent} ` : ` as much as 10% ` }
      more on average.
    </Fragment>
  );

  const daysOnMarketCopy = (
    <Fragment>
      Top <RealtorKW realtor={useRealtor}>agents</RealtorKW> close deals
      { top_agents_dom_diff_times ? ` ${top_agents_dom_diff_times} times ` : ' ' }
      faster than the average agent.
    </Fragment>
  );

  const avgRatingCopy = top_agents_avg_rating ? (
    <Fragment>
      Our <RealtorKW realtor={useRealtor}>agents</RealtorKW> have an average rating of
      { ` ${top_agents_avg_rating}/5 stars.` }
    </Fragment>
  ) : (
    <Fragment>
      We only recommend the top-performing <RealtorKW realtor={useRealtor}>agents</RealtorKW> working in your area.
    </Fragment>
  );

  const buyerAgentSavingsCopy = (
    <Fragment>
      Top <RealtorKW realtor={useRealtor}>agents</RealtorKW>
      { buyer_agents_saving_percent ? ` help you save ${buyer_agents_saving_percent} ` : ` negotiate on your behalf to get the best price ` }
      on your next home.
    </Fragment>
  );

  return (
    <div className='why-top-agents page-section'>
      <div className="content">
        <h2 className="title">
          Why work with top <RealtorKW realtor={useRealtor}>agents</RealtorKW>
          { cityName ? ` in ${cityName}` : "" }
        </h2>

        <div className="tabs-heading">
          <span className={`tab tab--${selectedTab === SELLER_TAB ? "active" : "inactive"}`} onClick={() => handleClick(SELLER_TAB)}>
            I'm selling
          </span>
          <span className={`tab tab--${selectedTab === BUYER_TAB ? "active" : "inactive"}`} onClick={() => handleClick(BUYER_TAB)}>
            I'm buying
          </span>
        </div>

        { selectedTab === SELLER_TAB && (
          <div className="tab-content tab-content--seller">
            <div className="img-wrapper img-wrapper">
              <picture>
                <source type="image/webp" srcSet={sellerImgWebp} />
                <source type="image/png" srcSet={sellerImgPng} />
                <img src={sellerImgPng} />
              </picture>
            </div>

            <div className="text-wrapper features">
              <div className="feature feature--sell">
                <div className="feature__icon">
                  <BillIcon />
                </div>

                <div className="feature__text">
                  <h3 className="feature__title">
                    Sell for more
                  </h3>
                  <p className="feature__description">
                    {sellerAgentPriceCopy}
                  </p>
                </div>
              </div>

              <div className="feature feature--close">
                <div className="feature__icon">
                  <CalendarSolidIcon />
                </div>

                <div className="feature__text">
                  <h3 className="feature__title">
                    Close faster
                  </h3>
                  <p className="feature__description">
                    {daysOnMarketCopy}
                  </p>
                </div>
              </div>

              <div className="feature feature--sell">
                <div className="feature__icon">
                  <StarIcon fill="#273653" />
                </div>

                <div className="feature__text">
                  <h3 className="feature__title">
                    Work with the best
                  </h3>
                  <p className="feature__description">
                    {avgRatingCopy}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        { selectedTab === BUYER_TAB && (
          <div className="tab-content tab-content--buyer">
            <div className="img-wrapper img-wrapper">
              <picture>
                <source type="image/webp" srcSet={buyerImgWebp} />
                <source type="image/png" srcSet={buyerImgPng} />
                <img src={buyerImgPng} />
              </picture>
            </div>

            <div className="text-wrapper features">
              <div className="feature feature--sell">
                <div className="feature__icon">
                  <BillIcon />
                </div>

                <div className="feature__text">
                  <h3 className="feature__title">
                    Save thousands
                  </h3>
                  <p className="feature__description">
                    {buyerAgentSavingsCopy}
                  </p>
                </div>
              </div>

              <div className="feature feature--close">
                <div className="feature__icon">
                  <CalendarSolidIcon />
                </div>

                <div className="feature__text">
                  <h3 className="feature__title">
                    Get local expertise
                  </h3>
                  <p className="feature__description">
                    Your top <RealtorKW realtor={useRealtor}>agent</RealtorKW> will guide you through
                    the homebuying process from start to finish.
                  </p>
                </div>
              </div>

              <div className="feature feature--sell">
                <div className="feature__icon">
                  <StarIcon fill="#273653" />
                </div>

                <div className="feature__text">
                  <h3 className="feature__title">
                    Work with the best
                  </h3>
                  <p className="feature__description">
                    {avgRatingCopy}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

WhyTopAgents.propTypes = {
  cityName: PropTypes.string,
  cityAgentsMetrics: PropTypes.shape({
    seller_agents_price_percent: PropTypes.string.isRequired,
    top_agents_dom_diff_times: PropTypes.string.isRequired,
    top_agents_avg_rating: PropTypes.string.isRequired,
    buyer_agents_saving_percent: PropTypes.string.isRequired,
  }),
  useRealtor: PropTypes.bool
}

WhyTopAgents.defaultProps = {
  cityName: null,
  cityAgentsMetrics: {},
  useRealtor: false
}

export default WhyTopAgents
