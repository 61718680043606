import React from 'react';
import PropTypes from 'prop-types';
import ReviewAverage from './global/ReviewAverage';
import MoneyCatch from '../../images/icons/money-catch.png';
import Clock from '../../images/icons/clock.png';
import AgentBadge from '../../images/icons/agent-badge.png';
import RealtorKW from '../../components/global/RealtorKW';
import '../../styles/agentMatchingSem/ServiceProps.scss';

const ServiceProps = ({ useRealtor }) => (
  <div className='service-props page-section'>
    <div className='content'>
      <ReviewAverage />
      <div className='service-props__props-list'>
        <div className='service-props__item'>
          <div className='service-props__image'>
            <img src={MoneyCatch} alt='Free and unbiased' />
          </div>
          <div className='service-props__prop-text'>
            <div className='service-props__prop-title'>
              Free and unbiased
            </div>
            <div className='service-props__prop-description'>
              Our service is 100% free, with no catch.
              Meet the best <RealtorKW realtor={useRealtor}>agents</RealtorKW> in your area.
            </div>
          </div>
        </div>
        <div className='service-props__item'>
          <div className='service-props__image'>
            <img src={Clock} alt='Save time and hassle' />
          </div>
          <div className='service-props__prop-text'>
            <div className='service-props__prop-title'>
              Save time and hassle
            </div>
            <div className='service-props__prop-description'>
              It takes less than 2 minutes to match you with a
              top <RealtorKW realtor={useRealtor}>agent</RealtorKW> who will guide you through the process.
            </div>
          </div>
        </div>
        <div className='service-props__item'>
          <div className='service-props__image'>
            <img src={AgentBadge} alt='Only the top agents' />
          </div>
          <div className='service-props__prop-text'>
            <div className='service-props__prop-title'>
              Only the top <RealtorKW realtor={useRealtor}>agents</RealtorKW>
            </div>
            <div className='service-props__prop-description'>
              We analyze over 27 million transactions and thousands of reviews to find
              the best <RealtorKW realtor={useRealtor}>agent</RealtorKW> for your needs.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ServiceProps.propTypes = {
  useRealtor: PropTypes.bool
}

ServiceProps.defaultProps = {
  useRealtor: false
}

export default ServiceProps;
