import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PlaceToQuiz from '../../components/loadable/global/PlaceToQuiz';
import RealtorKW from '../../components/global/RealtorKW';
import '../../styles/agentMatchingSem/CtaBanner.scss';
import '../../styles/agentMatchingSem/Cta.scss';

const CtaBanner = ({
  useRealtor,
  cityNameWithState,
  pickQuizPath
}) => {
  const buttonText = (
    <Fragment>
      Compare <RealtorKW realtor={useRealtor}>Agents</RealtorKW>
    </Fragment>
  );

  return (
      <div className='cta-banner'>
      <h2>
        Find a top <RealtorKW realtor={useRealtor}>real estate agent</RealtorKW> near you
      </h2>
      <div className='cta-banner__input'>
        <PlaceToQuiz
          id='agent-matching-banner-cta'
          buttonText={buttonText}
          url={pickQuizPath}
          types='place'
          default={cityNameWithState}
          placeHolder='Enter your city'
          trackPageName='agentMatchingSem'
          trackPagePosition='hero'
          trackEventCategory='Generic_Agent_Matching'
          trackingAction='Tap'
          trackingLabel='Generic_Agent_Matching_Compare_Agents_Final_CTA'
          showSearchIcon
        />
      </div>
    </div>
  )
};

CtaBanner.propTypes = {
  useRealtor: PropTypes.bool,
  cityNameWithState: PropTypes.string,
  pickQuizPath: PropTypes.string.isRequired
};

CtaBanner.defaultProps = {
  cityNameWithState: null,
  useRealtor: false
};

export default CtaBanner;
