import React, { Fragment } from 'react';

const RealtorKW = ({ children = '', realtor = false }) => {
  if (!children || !realtor) {
    return children;
  }

  const copyWords = children.split(' ');
  const hasArticle = copyWords.length > 1 && ['a', 'an'].includes(copyWords[0].toLowerCase());
  const copySuffix = children.match(/agent(.*)/i)[1]?.toUpperCase() || '';

  const realtorWord = hasArticle ? `${copyWords[0].charAt(0)} REALTOR` : 'REALTOR';
  return (
    <Fragment>
      {`${realtorWord}${copySuffix}`}
      <sup className='registered-trademark'>®</sup>
    </Fragment>
  );
};

export default RealtorKW;
