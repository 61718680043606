import * as Sentry from '@sentry/react';

const dsn = process.env.SENTRY_FRONTEND_DSN;

export const initializeSentry = () => {
  const hostname = globalThis.location?.hostname;
  if (!hostname) {
    return;
  }

  let environment = '';
  if (/sandbox|demo|staging/.test(hostname)) {
    environment = 'staging';
  } else if (/release/.test(hostname)) {
    environment = 'release';
  } else if (/local|dev/.test(hostname)) {
    environment = 'development';
  } else if (/homelight/.test(hostname)) {
    environment = 'production';
  }

  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    environment: environment,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()]
  });

  if (window) window.Sentry ||= Sentry
};
