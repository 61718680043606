import React from 'react';
import ColdwellIcon from './icons/ColdwellIcon';
import RemaxIcon from './icons/RemaxIcon';
import CompassIcon from './icons/CompassIcon';
import KellerWilliamsIcon from './icons/KellerWilliamsIcon';
import CenturyIcon from './icons/CenturyIcon';
import BerkshireIcon from './icons/BerkshireIcon';
import '../../styles/agentMatchingSem/Brands.scss';

const Brands = () => {
  return (
    <div className="brands">
      <h2 className="brands__header">Brands we work with</h2>
      <div className="brands__icon-container">
        <div className="brands__icon-wrapper"><ColdwellIcon /></div>
        <div className="brands__icon-wrapper"><RemaxIcon /></div>
        <div className="brands__icon-wrapper"><CompassIcon /></div>
        <div className="brands__icon-wrapper"><KellerWilliamsIcon /></div>
        <div className="brands__icon-wrapper"><CenturyIcon /></div>
        <div className="brands__icon-wrapper"><BerkshireIcon /></div>
      </div>
    </div>
  );
};

export default Brands;
