import React from 'react';

const BillIcon = () => (
  <svg className="bill-icon" width="25" height="19" viewBox="0 0 25 19">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.4533 -2.82942e-05H1.85563C1.69785 -0.000332011 1.54156 0.0304828 1.3957 0.0906524C1.24984 0.150822 1.11728 0.239165 1.00561 0.350626C0.893933 0.462086 0.805335 0.594476 0.744885 0.740217C0.684435 0.885959 0.65332 1.04219 0.65332 1.19997V13.2C0.65332 13.5182 0.779748 13.8235 1.00479 14.0485C1.22984 14.2735 1.53506 14.4 1.85332 14.4H23.451C23.7693 14.4 24.0745 14.2735 24.2995 14.0485C24.5246 13.8235 24.651 13.5182 24.651 13.2V1.19997C24.651 0.882112 24.5249 0.577237 24.3004 0.352259C24.0758 0.127282 23.7712 0.000583563 23.4533 -2.82942e-05ZM2.45388 4.20055V1.80055H4.85388C4.85357 2.43698 4.60062 3.04725 4.15059 3.49727C3.70057 3.94729 3.0903 4.20025 2.45388 4.20055ZM22.8537 4.20055C22.2172 4.20055 21.6067 3.9477 21.1567 3.49761C20.7066 3.04752 20.4537 2.43707 20.4537 1.80055H22.8537V4.20055ZM20.4537 12.6006H22.8537V10.2006C22.2172 10.2006 21.6067 10.4534 21.1567 10.9035C20.7066 11.3536 20.4537 11.964 20.4537 12.6006ZM2.45388 12.6006V10.2006C3.0904 10.2006 3.70084 10.4534 4.15093 10.9035C4.60102 11.3536 4.85388 11.964 4.85388 12.6006H2.45388ZM9.6539 7.20021C9.6539 9.18327 10.9994 10.8002 12.6568 10.8002C14.313 10.8002 15.6597 9.18327 15.6597 7.20021C15.6597 5.21715 14.3165 3.60021 12.6568 3.60021C10.9971 3.60021 9.6539 5.21715 9.6539 7.20021Z" fill="currentColor"/>
    <path d="M24.1913 16.2006H1.1153C0.860157 16.2006 0.65332 16.4074 0.65332 16.6626V17.5392C0.65332 17.7943 0.860157 18.0011 1.1153 18.0011H24.1913C24.4465 18.0011 24.6533 17.7943 24.6533 17.5392V16.6626C24.6533 16.4074 24.4465 16.2006 24.1913 16.2006Z" fill="currentColor"/>
  </svg>
)

export default BillIcon
